<template>
  <div>
    <v-form ref="form" v-model="validForm" lazy-validation>
      <v-row class="pt-5">
        <v-col cols="6" class="py-1 my-0">
          <v-text-field
            class="label-color"
            dense
            clearable
            v-model="first_name"
            type="text"
            :rules="[getRules.required]"
            :error-messages="getAuthErrors.first_name"
            @keyup.enter="validate"
            tabindex="1"
          >
            <template v-slot:label>
              <span class="input__label black--text">First Name</span>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="6" class="py-1 my-0">
          <v-text-field
            dense
            clearable
            v-model="last_name"
            type="text"
            :rules="[getRules.required]"
            :error-messages="getAuthErrors.last_name"
            @keyup.enter="validate"
            tabindex="2"
          >
            <template v-slot:label>
              <span class="input__label black--text">Last Name</span>
            </template>
          </v-text-field>
        </v-col>

        <v-col cols="12" class="py-1 my-0">
          <v-text-field
            dense
            clearable
            v-model="organization_name"
            :rules="[getRules.required]"
            @keyup.enter="validate"
            tabindex="3"
          >
            <template v-slot:label>
              <span class="input__label black--text">Company Name</span>
            </template>
          </v-text-field>
        </v-col>

        <v-col cols="12" class="py-1 my-0">
          <v-text-field
            dense
            clearable
            v-model="job_title"
            type="text"
            :rules="[getRules.required]"
            :error-messages="getAuthErrors.job_title"
            @keyup.enter="validate"
            tabindex="4"
          >
            <template v-slot:label>
              <span class="input__label black--text">Position</span>
            </template>
          </v-text-field>
        </v-col>

        <v-col cols="12" class="py-1 my-0">
          <v-text-field
            dense
            clearable
            v-model="email"
            type="text"
            :rules="[getRules.required, getRules.email]"
            :error-messages="getAuthErrors.email"
            @keyup.enter="validate"
            tabindex="5"
          >
            <template v-slot:label>
              <span class="input__label black--text">Email Address</span>
            </template>
          </v-text-field>
        </v-col>

        <v-col cols="12" class="py-1 my-0">
          <v-text-field
            dense
            clearable
            v-model="password"
            :type="showPass ? 'text' : 'password'"
            :rules="[getRules.required, limit]"
            :error-messages="getAuthErrors.password"
            :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPass = !showPass"
            @keyup.enter="validate"
            tabindex="5"
          >
            <template v-slot:label>
              <span class="input__label black--text">Password</span>
            </template>
          </v-text-field>
        </v-col>

        <v-col cols="12" class="py-1 my-0">
          <v-text-field
            dense
            clearable
            v-model="confirm_password"
            :type="showPass ? 'text' : 'password'"
            :rules="[getRules.required, passwordMismatch]"
            :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPass = !showPass"
            @keyup.enter="validate"
            tabindex="6"
          >
            <template v-slot:label>
              <span class="input__label black--text">Confirm Password</span>
            </template>
          </v-text-field>
        </v-col>

        <v-col cols="12" class="py-1 my-0">
          <v-checkbox color="primary" class="py-0 my-0">
            <template v-slot:label class="py-0 my-0">
              <div class="black--text">
                I want to subscribe to the newsletter.
              </div>
            </template>
          </v-checkbox>
          <div class="d-flex f-12 center-align">
            <v-checkbox color="primary" class="py-0 my-0" :rules="[getRules.required]" v-model="areTermsAccepted">
              <template v-slot:label>
                <span class="black--text text-wrap">I've read and accept the </span>
              </template>
            </v-checkbox>

            <a
              class="ml-1 color-primary center-align"
              href="/#/terms-and-conditions"
              target="_blank"
              style="margin-top: 2px;"
            >
              Terms and Conditions.</a
            >
          </div>
        </v-col>

        <v-col cols="12" class="py-1 my-0 text-right">
          <v-btn color="primary" dark :loading="getSignupStatus === 1" @click="validate">Create Account</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Signup",
  data: () => ({
    validationMessage: "",
    validForm: true,
    showPass: false,
    email: "",
    password: "",
    confirm_password: "",
    first_name: "",
    last_name: "",
    job_title: "",
    organization_name: "",
    areTermsAccepted: false
  }),
  /*beforeRouteEnter(to, from, next) {
    next(vm => {
      if (vm.isAuthenticated) next({name: 'home'});
    })
  },*/
  computed: {
    ...mapGetters([
      "getRules",
      "getAuthErrors",
      "getSignupStatus",
      "isAuthenticated",
      "getForgotStatus",
      "getForgotErrors",
      "getForgotMessage"
    ])
  },
  methods: {
    passwordMismatch() {
      if (this.password !== null && this.confirm_password !== null && this.password !== this.confirm_password) {
        return "Passwords don't match";
      }
      return true;
    },
    limit() {
      if (this.password !== null && this.password.length < 8) {
        return "Password must be 8 characters or more";
      }
      return true;
    },
    async validate() {
      if (this.$refs.form.validate()) {
        await this.signup({
          first_name: this.first_name,
          last_name: this.last_name,
          email: this.email,
          job_title: this.job_title,
          password: this.password,
          organization_name: this.organization_name
        });
        this.reset();
        this.$emit("setTab", 1);
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    ...mapActions(["signup"])
  }
};
</script>
<style scoped>
.f-12 {
  font-size: 13px;
}
.text-wrap {
  word-break: break-word;
}
</style>
