<template>
  <div>
    <v-form ref="form" v-model="validForm" lazy-validation>
      <v-row class="pt-5">
        <v-col cols="12" class="my-0 py-1">
          <v-text-field
            tabindex="1"
            dense
            clearable
            v-model="email"
            type="text"
            :rules="[getRules.required, getRules.email]"
            :error-messages="getAuthErrors.email"
            @keyup.enter="validate"
          >
            <template v-slot:label>
              <span class="input__label black--text">Email Address</span>
            </template>
          </v-text-field>
        </v-col>

        <v-col cols="12" class="my-0 py-1">
          <v-text-field
            tabindex="2"
            dense
            clearable
            v-model="password"
            :type="showpass ? 'text' : 'password'"
            :rules="[getRules.required]"
            :error-messages="getAuthErrors.password"
            :append-icon="showpass ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showpass = !showpass"
            @keyup.enter="validate"
          >
            <template v-slot:label>
              <span class="input__label black--text">Password</span>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="6" class="my-0 py-1">
          <v-checkbox
            tabindex="3"
            color="primary"
            v-model="rememberme"
            :value="rememberme"
            class="py-0 my-0"
          >
            <template v-slot:label>
              <div class="black--text">Remember me</div>
            </template>
          </v-checkbox>
        </v-col>
        <v-col cols="6" class="text-right my-0 py-1">
          <a tabindex="4" @click="dialog = true">Forgot password?</a>
        </v-col>
        <v-col cols="12" class="text-right">
          <v-btn
            tabindex="5"
            color="primary"
            dark
            @click="validate"
            :loading="getAuthStatus === 1"
            >Login</v-btn
          >
        </v-col>
      </v-row>
    </v-form>

    <v-row justify="center">
      <v-dialog
        v-model="dialog"
        persistent
        max-width="350"
        transition="dialog-top-transition"
      >
        <v-card>
          <v-card-title class="headline">
            <v-row>
              <v-col cols="8">Can't log in?</v-col>
              <v-col cols="4" class="text-right">
                <v-btn icon @click="dialog = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-subtitle>We'll send a recovery link to</v-card-subtitle>
          <v-card-text>
            <v-text-field
              outlined
              dense
              clearable
              v-model="recoveryEmail"
              label="Email"
              type="text"
              :rules="[getRules.required, getRules.email]"
              :error-messages="getForgotErrors.email"
              :success-messages="getForgotMessage.message"
            />
            <v-btn
              width="100%"
              @click="sendRecoveryLink"
              class="primary"
              :loading="getForgotStatus === 1"
              >Send recovery link</v-btn
            >
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Login",
  data: () => ({
    activeTab: null,
    validForm: true,
    bgImage: require('../../assets/images/auth-bg.png'),
    dialog: false,
    recoveryEmail: null,
    rememberme: false,
    showpass: false,
    email: "",
    password: "",
  }),
  /*beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.isAuthenticated) next({ name: "home" });
    });
  },*/
  computed: {
    ...mapGetters([
      "getRules",
      "getAuthErrors",
      "getAuthStatus",
      "isAuthenticated",
      "getForgotStatus",
      "getForgotErrors",
      "getForgotMessage",
    ]),
  },
  methods: {
    async sendRecoveryLink() {
      this.forgotPassword({
        email: this.recoveryEmail,
      });
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.login({
          email: this.email,
          password: this.password,
        });
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    ...mapActions(["login", "forgotPassword"]),
  },
};
</script>

<style scoped>
.width {
  width: 70px;
}
.auth-card-width {
  width: 60%;
}
</style>